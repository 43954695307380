// ==========================================================================
// About
// ==========================================================================

@use '../../../node_modules/sass-mq/mq';

.about {
  max-width: 100%;
  padding-bottom: 135px;
  position: relative;
  z-index: 10;

  &:before {
    background: transparent url(../img/break--about.png) no-repeat center 0;
    background-size: 2500px 221px;
    content: '';
    display: block;
    height: 221px;
    left: 0;
    position: absolute;
    top: -200px;
    width: 100%;
    z-index: -1;
  }
}

.about__picture {
  float: right;
}

@include mq.mq(desktop) {
  .about__content {
    padding-bottom: 115px;
  }
}