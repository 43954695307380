// ==========================================================================
// Footer
// ==========================================================================

@use 'sass:math';

.footer {
  background: transparent url(../img/break--footer.png) no-repeat center center;
  background-size: 2500px 137px;
  height: 137px;

  .realisations & {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.navigation-footer {
  text-align: right;
}

.navigation-footer__item {
  color: $white;
  display: block;
  font-size: math.div(1.4rem, 1.6);
  font-weight: $light;
  line-height: math.div(2.1rem, 1.6);
  margin-top: 80px;
  text-transform: lowercase;

  &:hover,
  &:active {
    color: $white;
    text-decoration: underline;
  }
}