// ==========================================================================
// Project
// ==========================================================================

@use '../../../node_modules/sass-mq/mq';
@use 'sass:math';

.realisations {
  background-color: $oracle;
}

.projects {
  padding-bottom: 85px;
  padding-top: 125px;
}

.projects__col {
  padding: 0;
}

.project {
  background: transparent url(../img/ellipsis.gif) no-repeat center center;
  position: relative;
  min-height: 1000px;
}

.project__thumbnail {
  transition: opacity 0.25s ease-in-out;
}

.project__content {
  color: $white;
  padding: 15px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(0,-50%);
  width: 100%;
}

.project__text {
  margin-top: 5px;
  padding: 5px;
  position: relative;
  z-index: 1;

  &:before {
    background-color: #282828;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0.75;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
}

.project__title {
  font-size: math.div(1.4rem, 1.6);
  font-weight: $light;
  line-height: math.div(2rem, 1.6);
  margin: 0;
}

.project__description p {
  font-size: math.div(1.4rem, 1.6);
  line-height: math.div(2rem, 1.6);
  margin: 0;
}

.project__architect {
  color: $white;
  font-size: math.div(1.2rem, 1.6);
  font-style: italic;
  font-weight: $light;
  line-height: math.div(1.8rem, 1.6);
  margin: 0;

  span {
    text-decoration: underline;
  }

  &:hover {
    color: $white;
  }
}

@include mq.mq(tablet) {
  .projects {
    padding-bottom: 0;
    padding-top: 90px;
  }

  .project__text {
    margin-top: 15px;
    padding: 15px;
  }

  .project__title {
    font-size: math.div(1.6rem, 1.6);
    font-weight: $bold;
    line-height: math.div(2.2rem, 1.6);
  }

  .project__description p {
    font-size: math.div(1.6rem, 1.6);
    line-height: math.div(2.2rem, 1.6);
  }

  .project__architect {
    font-size: math.div(1.6rem, 1.6);
    line-height: math.div(2.2rem, 1.6);
  }
}

@include mq.mq(desktop) {
  .project__overlay {
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: 0 !important;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.25s ease-in-out;

    &:before {
      background-color: #282828;
      bottom: 0;
      content: '';
      left: 0;
      opacity: 0.75;
      position: absolute;
      right: 0;
      top: 0;
    }

    &:hover {
      opacity: 1 !important;
    }
  }

  .project__text {
    margin-top: 5px;
    padding: 0;

    &:before {
      content: none;
    }
  }

  .project__architect {
    span {
      text-decoration: none;
    }

    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }
}