// ==========================================================================
// Utils
// ==========================================================================

@use '../../../node_modules/sass-mq/mq';

.text--center {
  text-align: center;
}

.text--right {
  text-align: right;
}

@include mq.mq(wide) {
  .block-table {
    display: table;
  }

  .block-table__cell {
    display: table-cell;
    float: none !important;
    vertical-align: middle;
  }

  .block-table__cell--bottom {
    vertical-align: bottom;
  }
}