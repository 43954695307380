// ==========================================================================
// Header
// ==========================================================================

@use '../../../node_modules/sass-mq/mq';
@use 'sass:math';

.header {
  left: 0;
  position: fixed;
  top: 0;
  transition: transform 0.25s ease-in-out;
  width: 100%;
  z-index: 50;

  &.header--up {
    transform: translate(0, -180px);
  }
}

.header-front {
  background: transparent url(../img/break--header.png) no-repeat center center;
  background-size: 2500px 180px;
  height: 180px;
  z-index: 20;
}

.header-back {
  z-index: 10
}

.logo {
  margin: 0;
  padding: 0;

  a {
    display: block;
    line-height: 160px;
    width: 70%;
  }

  img {
    display: inline;
  }
}

.navigation-toggle {
  height: 40px;
  position: absolute;
  right: 15px;
  top: 45px;
}

.navigation-toggle__opened {
  display: none;
}

.navigation-toggle--opened {
  .navigation-toggle__closed {
    display: none;
  }

  .navigation-toggle__opened {
    display: block;
  }
}

.navigation {
  background-color: $rangoon-green;
  left: 0;
  opacity: 0;
  padding: 40px 0 20px;
  position: absolute;
  text-align: center;
  transform: translate(0, -500px);
  transition: opacity 0.25s ease-out, transform 0.25s ease-out;
  width: 100%;
}

.navigation--active {
  opacity: 1;
  transform: translate(0, -60px);
}

.navigation__item {
  display: block;
  font-family: $font-title;
  font-size: math.div(math.div(2rem, 1.6), 1.6);
  line-height: 45px;
  padding: 0 15px;
  -webkit-font-smoothing: auto;

  &:hover,
  &:active,
  &.navigation__item--active {
    color: $white;
  }

  &[href^="tel:"] {
    display: inline-flex;
    align-items: center;
    font-weight: 700;

    &::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48'%3E%3Cpath d='M31 2H15c-2.76 0-5 2.24-5 5v34c0 2.76 2.24 5 5 5h16c2.76 0 5-2.24 5-5V7c0-2.76-2.24-5-5-5zm-8 42c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm9-8H14V8h18v28z' fill='%23716948'/%3E%3C/svg%3E");
      background-size: contain;
      content: '';
      display: block;
      height: 32px;
      transition: background-image .25s ease-in-out;
      width: 32px;
    }
    
    &:hover::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48'%3E%3Cpath d='M31 2H15c-2.76 0-5 2.24-5 5v34c0 2.76 2.24 5 5 5h16c2.76 0 5-2.24 5-5V7c0-2.76-2.24-5-5-5zm-8 42c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm9-8H14V8h18v28z' fill='%23ffffff'/%3E%3C/svg%3E");
    }
  }
}

@include mq.mq(tablet) {
  .navigation__item {
    font-size: math.div(1.6rem, 1.6);
  }
}

@include mq.mq(desktop) {
  .header {
    background: transparent url(../img/break--header.png) no-repeat center center;
    background-size: 2500px 180px;
    height: 180px;
  }

  .header-front {
    background-image: none;
  }


  .logo {
    padding: 30px 0;

    a {
      display: block;
      line-height: math.div(1rem, 1.6);
      width: 100%;
    }

    img {
      display: block;
    }
  }

  .navigation-toggle {
    display: none;
  }

  .navigation {
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
    opacity: 1;
    padding: 0;
    position: static;
    transform: translate(0, 0);
    text-align: right;
    width: auto;
  }

  .navigation__item {
    line-height: 130px;

    &:last-child {
      padding-right: 0;
    }
  }
}

@include mq.mq(wide) {
  .navigation__item {
    font-size: math.div(2rem, 1.6);
    padding: 0 20px;

    &:last-child {
      padding-right: 0;
    }
  }
}