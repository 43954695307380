// ==========================================================================
// Tabs
// ==========================================================================

@use '../../../node_modules/sass-mq/mq';
@use 'sass:math';

.tabs {
  &__toggles {
    display: flex;
  }

  &__toggle {
    background-color: #eee;
    border: none;
    border-bottom: 3px solid $go-ben;
    border-radius: 15px 15px 0 0;
    color: $go-ben;
    display: inline-block;
    flex: 1;
    font-family: $font-title;
    font-size: math.div(1.8rem, 1.6);
    line-height: 50px;
    padding: 0 15px;
    text-align: center;
    transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
    
    &:hover {
      background-color: $go-ben;
      color: $white;
    }
    
    &--active {
      background-color: transparent;
      border: 3px solid $go-ben;
      border-bottom: none;
      border-color: $go-ben;
      pointer-events: none;
    }
  }
  
  &__contents {
    border: 3px solid $go-ben;
    border-top: none;
    display: grid;
    grid-template-areas: 'content';
    padding: 30px 15px;
  }

  &__content {
    grid-area: content;
    max-width: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out;
    
    &--active {
      display: block;
      pointer-events: all;
      opacity: 1;
    }

    ul {
      font-size: math.div(1.8rem, 1.6);
      font-weight: $light;
      line-height: math.div(2.6rem, 1.6);
      padding: 0;
      margin: 0 0 25px 30px;

      li {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}