// ==========================================================================
// Variables
// ==========================================================================

// Colors

$rangoon-green: #1d1b12;
$go-ben: #716948;
$mongoose: #afa370;
$oil: #1f1912;
$oracle: #326f68;

$black: $oil;
$white: #fff;

$color-black: $black;
$color-white: $white;

$color-foreground: $rangoon-green;
$color-background: $white;

// Fonts
$font-default: 'Open Sans', sans-serif;
$font-title: 'ZonaPro', sans-serif;
$font-sans-serif: 'Open Sans', sans-serif;
$font-serif: serif;
$font-code: Courier, monospace;

// Font-weight
$light: 300;
$regular: 400;
$bold: 700;
