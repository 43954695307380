// ==========================================================================
// Showcase
// ==========================================================================

@use '../../../node_modules/sass-mq/mq';
@use 'sass:math';

.showcase {
  background-color: $rangoon-green;
  color: $mongoose;
  max-width: 100%;
  padding-bottom: 55px;
  position: relative;
  z-index: 10;

  &:before {
    background: transparent url(../img/break--showroom-1.png) no-repeat center 0;
    background-size: 2500px 220px;
    content: '';
    display: block;
    height: 195px;
    left: 0;
    position: absolute;
    top: -195px;
    width: 100%;
    z-index: -1;
  }

  &:after {
    background: transparent url(../img/break--showroom-2.png) no-repeat center 0;
    background-size: 2500px 218px;
    content: '';
    display: block;
    height: 218px;
    left: 0;
    position: absolute;
    top: -195px;
    width: 100%;
    z-index: 10;
  }
}

.showcase__shelfs {
  position: absolute;
  left: 20%;
  top: -220px;
  z-index: 5;
}

.showcase__content {
  background-color: $rangoon-green;
  position: relative;
  z-index: 15;
}

.showcase__description {
  font-size: math.div(1.6rem, 1.6);
  line-height: math.div(3rem, 1.6);
  margin-bottom: 135px;

  ul {
    display: flex;
    align-items: center;
    flex-flow: column;
    list-style-position: inside;
    padding: 0;
  }

  li:empty {
    &::marker {
      opacity: 0;
    }
  }
}

@include mq.mq(tablet) {
  .showcase__shelfs {
    left: 30%;
    margin-left: 50px;
    top: -240px;
  }
}

@include mq.mq(desktop) {
  .showcase__shelfs {
    left: 30%;
    margin-left: 50px;
    top: -240px;
  }
}

@include mq.mq(wide) {
  .showcase__shelfs {
    left: 50%;
    margin-left: 86px;
    top: -280px;
  }
}