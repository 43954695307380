// ==========================================================================
// Buttons
// ==========================================================================

@use '../../../node_modules/sass-mq/mq';
@use 'sass:math';

.buttons {
  text-align: center;
}

.button {
  background-color: transparent;
  border: 3px solid $go-ben;
  display: inline-block;
  font-family: $font-title;
  font-size: math.div(1.8rem, 1.6);
  line-height: 50px;
  padding: 0 15px;
  text-align: center;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;

  &:hover {
    background-color: $go-ben;
    color: $rangoon-green;
  }

  form & {
    padding: 0 100px;
    &:hover {
      color: $white;
    }
  }
}

.button--mongoose {
  border-color: $mongoose;
  color: $mongoose;

  &:hover {
    background-color: $mongoose;
    color: $rangoon-green;
  }
}

.button--disabled,
.button[disabled] {

}

@include mq.mq(tablet) {
  .button {
    border-width: 4px;
    font-size: math.div(2.2rem, 1.6);
    line-height: 66px;
    padding: 0 50px;
  }
}

@include mq.mq(desktop) {
  .button {
    border-width: 6px;
    font-size: math.div(3.2rem, 1.6);
    line-height: 88px;
    padding: 0 115px;

    form & {
      width: 100%;
    }
  }
}