// ==========================================================================
// Base
// ==========================================================================

*,
*:before,
*:after {
  box-sizing: border-box;
}

*:focus,
*:hover,
*:active {
  outline: 0 none;
}

html {
  background-color: $oil;
  height: 100%;
}

html,
html a {
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
}

body {
  background-color: $white;
  color: $color-foreground;
  font-family: $font-default;
  font-weight: normal;
  margin: 0 auto;
  max-width: 2500px;
  min-height: 100%;
  overflow-x: hidden;
  position: relative;
  text-size-adjust: none;
}