// ==========================================================================
// Page
// ==========================================================================

@use 'sass:math';

.page {
  background-color: $white;
  padding-bottom: 60px;
}

.page__banner {
  background-color: $rangoon-green;
  height: 600px;
  position: relative;
  margin-bottom: 110px;

  &:before {
    background-position: center center;
    background-size: cover;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0.5;
    position: absolute;
    right: 0;
    top: 0;
  }

  &:after {
    background: transparent url(../img/break--about.png) no-repeat center 0;
    background-size: 2500px 221px;
    content: '';
    display: block;
    height: 221px;
    left: 0;
    position: absolute;
    bottom: -150px;
    width: 100%;
    z-index: 5;
  }
}

.page__header {
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
}

.page__title {
  color: $white;
  margin: 0;
}

.page__content {
  h2, h3 {
    color: $go-ben;
    font-family: $font-title;
    font-weight: $light;
  }

  h2 {
    font-size: math.div(3.2rem, 1.6);
    margin: 40px 0 16px;
  }

  h3 {
    font-size: math.div(2.2rem, 1.6);
    margin: 35px 0 16px;
  }
}