// ==========================================================================
// Title
// ==========================================================================

@use '../../../node_modules/sass-mq/mq';
@use 'sass:math';

.title {
  color: $go-ben;
  font-family: $font-title;
  font-size: math.div(4rem, 1.6);
  font-weight: normal;
  margin: 15px 0 90px;
  text-align: center;
}

.title--small {
  font-size: math.div(3rem, 1.6);
  margin: 20px 0 40px;
}

.title--mongoose {
  color: $mongoose;
}

.title-icon {
  text-align: center;
}

@include mq.mq(tablet) {
  .title {
    font-size: math.div(5rem, 1.6);
  }

  .title--small {
    font-size: math.div(3.2rem, 1.6);
  }
}