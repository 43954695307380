// ==========================================================================
// Contact
// ==========================================================================

@use '../../../node_modules/sass-mq/mq';
@use 'sass:math';

.contact {
  background-color: $white;
  max-width: 100%;
  padding-top: 150px;
  position: relative;
  z-index: 10;

  &:before {
    background: transparent url(../img/break--contact.png) no-repeat center 0;
    background-size: 2500px 89px;
    content: '';
    display: block;
    height: 89px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.alert {
  color: $white;
  font-size: math.div(1.8rem, 1.6);
  font-weight: $light;
  margin-bottom: 40px;
  padding: 15px;
  text-align: center;

  &.green {
    background-color: $oracle;
  }

  &.red {
    background-color: red;
  }
}

.contact__node {
  margin: 30px 0;
}

.contact__shelf {
  margin: 100px 0 45px;
}

.contact__partners {
  padding-bottom: 45px;
}

.contact__partners-title {
  color: $oracle;
  font-size: math.div(2.4rem, 1.6);
  line-height: math.div(3.6rem, 1.6);
  font-weight: $bold;
  margin-bottom: 0;
  
  @include mq.mq(tablet) {
    font-size: math.div(3.2rem, 1.6);
    line-height: math.div(4.8rem, 1.6);
  }
}

.contact__partners-list {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  
  @include mq.mq(tablet) {
    align-items: flex-start;
    justify-content: center;
    flex-flow: row;
  }
}

.contact__partner {
  color: $oil;
  font-size: math.div(1.8rem, 1.6);
  font-weight: 300;
  line-height: math.div(2.6rem, 1.6);
  padding: 15px 13px;
  text-align: center;

  img {
    margin: 0 0 5px;
  }
}

.address {
  font-style: normal;
}

.address__logo {
  display: inline-block;
  margin-bottom: 40px;
}

.address__content {
  color: $oil;
  font-size: math.div(1.8rem, 1.6);
  line-height: math.div(2.6rem, 1.6);
  font-weight: $light;
  margin-bottom: 10px;

  @include mq.mq(tablet) {
    font-size: math.div(2.4rem, 1.6);
    line-height: math.div(3.6rem, 1.6);
  }
}

.address__phone {
  font-size: math.div(1.8rem, 1.6);
  line-height: math.div(2.6rem, 1.6);
  font-weight: $bold;

  a {
    color: $oracle;
  }
  
  @include mq.mq(tablet) {
    font-size: math.div(3.2rem, 1.6);
    line-height: math.div(4.8rem, 1.6);
  }
}