// ==========================================================================
// Slider
// ==========================================================================

@use '../../../node_modules/sass-mq/mq';
@use 'sass:math';

.slider {
  background: $oil url(../img/ellipsis-2.gif) no-repeat center center;
  height: 750px;
  overflow: hidden;
  position: relative;
}

.slider__item {
  background: $oil;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 750px;
  position: relative;
  padding: 0 30px;
  z-index: 0;
  
  &::before {
    background-position: center center;
    background-size: cover;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0.5;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  
  &::after {
    background: $black;
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    opacity: .5;
    z-index: 0;
  }

  @include mq.mq(tablet) {
    padding: 0 60px;
  }
}

.slider__title {
  color: $white;
  font-family: $font-title;
  font-size: math.div(2rem, 1.6);
  text-align: center;
  position: relative;
  width: 100%;
  z-index: 1;
}

.slider__desc {
  color: $white;
  position: relative;
  text-align: center;
  z-index: 1;
  
  @include mq.mq(tablet) {
    font-weight: bold;
    width: 75%;
  }
}

.slider__button {
  position: relative;
  z-index: 1;
}

.slider__img {
  display: none !important;
}

.slider-controllers {
  pointer-events: none;
  left: 0;
  position: absolute;
  top: 50%;
  width: 100%;
}

.slider-controllers__item {
  pointer-events: all;
  float: left;
  position: relative;
  z-index: 20;
  width: 15px;
  
  @include mq.mq(tablet) {
    width: 30px;
  }
}

.slider-controllers__item--next {
  float: right;
}

.owl-dots {
  margin-top: 20px;
  position: absolute;
  inset: auto auto 200px;
  text-align: center;
  width: 100%;
  z-index: 20;
}

.owl-dot {
  background-color: $go-ben;
  border: none;
  border-radius: 8px;
  display: inline-block;
  height: 16px;
  margin: 0 3px;
  padding: 0;
  transition: background-color 0.25s ease-in-out;
  width: 16px;

  &:hover,
  &.active {
    background-color: $white;
  }
}

@include mq.mq(tablet) {
  .slider {
    height: 750px;
  }
  
  .slider__item {
    height: 750px;
  }

  .slider__title {
    font-size: math.div(3.2rem, 1.6);
  }

  .owl-dots {
    margin-top: 30px;
  }
}


@include mq.mq(desktop) {
  .slider {
    height: 100vh;
  }

  .slider__item {
    height: 100vh;
  }
}