// ==========================================================================
// Typography
// ==========================================================================

@use 'sass:math';

a {
  color: $go-ben;
  text-decoration: none;
  transition: color 0.25s ease-in-out;

  &:hover,
  &:active {
    color: $mongoose;
  }
}

strong,
b {

}

em,
i {

}

sub, sup {
  font-size: 60%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

p,
ul,
ol,
dl,
blockquote,
.blockquote,
table,
.table,
img,
figure,
.media {

}

small {

}

hr,
.hr {

}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

p {
  font-size: math.div(1.8rem, 1.6);
  font-weight: $light;
  line-height: math.div(2.6rem, 1.6);
  margin: 0 0 25px;
}

blockquote {

}