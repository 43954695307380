// ==========================================================================
// Form
// ==========================================================================

@use "sass:color";
@use 'sass:math';

.form {
  padding-bottom: 60px;

  &-result {
    &:not(:empty) {
      margin-top: 30px;
    }
    
    .notices {
      border-left: 5px solid red;
      padding: 10px;
      
      p {
        margin: 0;
      }
    }

    .error {
      background: color.scale(#ff0000, $lightness: 90%);
    }
    
    .success {
      background: color.scale($oracle, $lightness: 90%);
      border-color: $oracle;
    }
  }
}

label {
  font-size: math.div(1.8rem, 1.6);
  font-weight: $light;
  display: inline-block;
  line-height: math.div(2.6rem, 1.6);
  margin-bottom: 5px;
}

.g-recaptcha {
  margin-bottom: 60px;
}