// ==========================================================================
// Icon
// ==========================================================================

@use '../../../node_modules/sass-mq/mq';

.icon {
  display: inline-block;
  transition: fill 0.25s ease-in-out;
  vertical-align: middle;
}

.icon--controller {
  fill: $white;

  &:hover {
    fill: $go-ben;
  }
}

.icon--menu,
.icon--white {
  fill: $white;
}

.icon--title,
.icon--node,
.icon--go-ben {
  fill: $go-ben;
}

.icon--cross {
  margin-top: 15px;
}

.icon--node {
  margin: 25px 0;
}

.icon--mongoose {
  fill: $mongoose;
}

.icon--oracle {
  fill: $oracle;
}

.icon--focus {
  fill: $white;
}

@include mq.mq(desktop) {
  .icon--focus {
    fill: $go-ben;
  }
}

