// ==========================================================================
// Input
// ==========================================================================

@use 'sass:math';

// Reset fuckin' ios8 input style
input[type=submit] {
  -webkit-appearance: none;
  border-radius: 0;
}

// Reset fuckin' mozilla button/input spaces
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin-top:-2px;
  margin-bottom: -2px;
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  border: 2px solid $rangoon-green;
  color: $rangoon-green;
  font-size: math.div(1.8rem, 1.6);
  font-weight: $light;
  line-height: math.div(4rem, 1.6);
  padding: 0 10px;
  margin-bottom: 40px;
  width: 100%;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px $white inset;
    -webkit-text-fill-color: $rangoon-green;

    .form--login & {
      -webkit-box-shadow: 0 0 0 1000px $white inset;
      -webkit-text-fill-color: $rangoon-green;
    }
  }
  &::-ms-clear {
    display: none;
  }
  &::-ms-reveal {
    display: none;
  }
}

textarea.input {
  line-height: math.div(3.2rem, 1.6);
  padding: 5px 10px;
}