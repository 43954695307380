// ==========================================================================
// Fonts
// ==========================================================================

@font-face {
  font-family: 'ZonaPro';
  src:  url('../fonts/ZonaPro/ZonaPro-Thin.woff2') format('woff2'),
        url('../fonts/ZonaPro/ZonaPro-Thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ZonaPro';
  src:  url('../fonts/ZonaPro/ZonaPro-Bold.woff2') format('woff2'),
        url('../fonts/ZonaPro/ZonaPro-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}